.outer-grid {
    display: flex;
    flex-direction: column;
}

.grid-row {
    display: flex;
    flex-direction: row;
    align-items: space-between;
}

.grid-element {
    flex: 1;
}


.wizard {
    background-color: lightgray;
    box-shadow: 0px 0px 10px black;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    max-width: 400px;
}

.unlock-btn {
    display: inline-block;
    color: #444;
    border: 1px solid #CCC;
    background: #DDD;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    vertical-align: middle;
    max-width: 100px;
    padding: 5px;
    text-align: center;
}

.unlock-btn:active {
    color: #DDD;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.6);
}