/* css for Sponsor.tsx */
#currency-table {
    border-collapse: collapse;
    width: 100%;
}

#currency-table td,
#rewards-table th {
    border: 1px solid #ddd;
    padding: 8px;
}

#currency-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

#currency-table tr:hover {
    background-color: #ddd;
}

#currency-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: darkgrey;
    color: white;
}

.sponsor-input {
   display: flex;
   flex-direction: column;
}

.sponsor-input input {

}

.sponsor-input label {
}

.nft-sponsorship-input {
display: flex;
flex-direction: column;
}