
.solve-automatically, .solve-manually, .Solve{
    border: 1px solid black;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}

.back-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.text-wrap {
    margin: auto;
    width: 50%;
    white-space: pre-wrap;
}

.wide-text-wrap {
    margin: auto;
    /* width: 0%; */
    white-space: pre-wrap;
}

.reward-list-overlay {
    position: fixed;
    z-index: 99;
    top: 400;
    flex: 1;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
}

.shadow {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 98;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flexOne {
    flex: 1;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.spinner-message-display {
    width: 600px;
    text-align: center;
}

.failed-interface {
    /* width: 600px; */
}