#rewards-table {
  border-collapse: collapse;
  width: 100%;
}

#rewards-table td, #rewards-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#rewards-table tr:nth-child(even){background-color: #f2f2f2;}

#rewards-table tr:hover {background-color: #ddd;}

#rewards-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: darkgrey;
  color: white;
}